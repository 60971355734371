import { domReady } from "@roots/sage/client";
import Swiper, { Navigation, Thumbs } from "swiper";
import { Fancybox } from "@fancyapps/ui";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
  faCopyright,
  faEnvelope,
  faFilePdf,
  faGlobe,
  faLockKeyhole,
  faPhoneRotary,
} from "@fortawesome/pro-light-svg-icons";

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  Fancybox.bind("[data-fancybox='gallery']", {
    Image: {
      zoom: true,
    },
  });

  library.add(
    faEnvelope,
    faGlobe,
    faPhoneRotary,
    faCopyright,
    faLockKeyhole,
    faFilePdf,
    faChevronRight,
    faChevronLeft,
    faFacebook
  );
  // tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
  dom.watch();

  // application code
  new Swiper(".hero", {
    // configure Swiper to use modules
    modules: [Navigation],
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  var swiper = new Swiper(".mySwiper", {
    spaceBetween: "auto",
    slidesPerView: 2,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var swiper2 = new Swiper(".mySwiper2", {
    modules: [Thumbs],
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });

  swiper2.init();

  jQuery(".btn").click(function () {
    var value = jQuery(this).attr("rel");

    jQuery(this).addClass("btn-active").siblings().removeClass("btn-active");

    jQuery(".ds_item").hide();

    jQuery(".ds_item")
      .filter(".date-" + value)
      .show();
  });
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
